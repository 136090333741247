* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Encode Sans Expanded", sans-serif;
}
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 0px violet;
}
