@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');


button {
  -webkit-appearance: none;
          appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
         filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
          transition: all .5s ease;
  border: none;
  background: #b4b4b4;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
}

.button .a {
color: red;
}
button:hover {
  color: #FFFFFF;
  background: #222222;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}
button:active, button:focus {
  outline: none;
  border: none;
}

.wrapper {
  max-width: 1335px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.wrapper a:first-child {
  margin-left: 0;
}
.slider-container{
  height: 90vh;
  background-color: red;;
}

.slider-wrapper {
  position: relative;
  height: 90vh;
  overflow: hidden;
}

.slide {
  height: 90vh;
  background-size: cover !important;
}

.slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9)));
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
    bottom: 0;
    left: 0;
}

.previousButton {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;
		background: url('../../images/left-arrow.svg') no-repeat center center / 35px;
		width: 32px;
		height: 32px;
		text-indent: -9999px;
		cursor: pointer;
}

.nextButton{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: url('../../images/right-arrow.svg') no-repeat center center / 35px;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  cursor: pointer;
}
.previousButton:hover{
  background: url('../../images/left-arrow.svg') no-repeat center center / 35px;
}
.nextButton:hover {
  background: url('../../images/right-arrow.svg') no-repeat center center / 35px;
}

.previousButton {
  left: 15px;
  /* -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
          transform: rotate(180deg) translateY(calc(50% + 0px)); */
}

.previousButton:hover {
  left: 5px;
}

.nextButton {
  right: 15px;
}

.nextButton:hover {
  right: 5px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 60%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.slider-content .inner button{
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #FFFFFF;
  font-size: 30px;
  line-height: 1;
}

.slider-content .inner p {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #FFFFFF;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #FFFFFF;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {
  .slider-wrapper, .slide {
    height: calc(100vh - 75px);
  }   
  .slider-content .inner h1 {
    font-size: 32px;
  } 
}

@media (max-width: 640px) {
  .slider-wrapper, .slide {
    height: calc(110vh - 80px);
  }    
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }    
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 28px;
  }
}