@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Encode Sans Expanded", sans-serif;
}
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 0px violet;
}


  .main-container {
    height:1600px;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    background:url(/static/media/background.49bad891.jpg);
    position: relative;   
  }

  .title {
    padding: 25px 0;
    text-align: center;
    color: black;
    position: absolute;
    top:10px;
    margin-top: 25px;
    font-family: "Encode Sans Expanded", sans-serif;
    font-size: 40px;
    font-weight: 700;
  }
  
  h2 {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  p {
    /* color: rgba(255, 255, 255, 0.596); */
    font-size: 1rem;
    /* text-align: center; */
  }
  
  .item {
    max-width: 1400px;
    margin: 0 auto;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    position: absolute;
    top: 250px;
  }

  .item-container {
    /* transition: all cubic-bezier(0.075, 0.82, 0.165, 1); */
    overflow: hidden;
  }

  .item-con {
    background: #ebe7e7;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
    border-radius: 10px;
    max-height: 450px;
    padding: 10px;
    box-shadow: 0 5px 10px rgba(201, 189, 189, 0.822);
    transition: all 0.2s ease-in-out;
  }
  .item-con:hover {
    -webkit-transform:scale(1.02);
            transform:scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor:pointer;
  }
  
  
  .item-container > img {
    width: 100%;
    padding: 5px;
    transition: all .3s ease-in-out;
  }
  .buttons {
    width: 70%;
    margin: 3rem auto;
    text-align: center;
    position: absolute;
    top: 100px;
  }
  
  .btn {
    padding: .5rem 2rem;
    background-color: #A6A6A6;
    border: none;
    font-family:  inherit;
    color: #fff;
    transition: all 0.4s cubic-bezier(1, -0.16, .6, 1.35); 
    box-shadow: 0 4px 8px 0 rgba(119, 105, 105, 0.521), 0 6px 20px 0 rgba(78, 73, 73, 0.671);
    margin-bottom:  10px;

  }
  
  .btn:not(:last-child){
    margin-right: 1rem;
  }
  
  .btn:hover{
    background-color: #C3C2C4;
    cursor: pointer;
    
  }
  
  .btn:active, .btn:focus{
    outline: none;
    border: none;
    background-color: #c5c4c5;
  
  }

  .btn2 {
    padding: .5rem 2rem;
    background-color: #A6A6A6;
    border: none;
    font-family:  inherit;
    color: #fff;
    transition: all 0.4s cubic-bezier(1, -0.16, .6, 1.35); 
    margin-bottom:  10px;

  }
  
  .btn2:not(:last-child){
    margin-right: 1rem;
  }
  
  .btn2:hover{
    background-color: #C3C2C4;
    cursor: pointer;
    
  }
  
  .btn2:active, .btn2:focus{
    outline: none;
    border: none;
    background-color: #c5c4c5;
  
  }


  @media screen and (max-width: 1000px) {
    .main-container{
        height:1600px;
    }
    
    .item {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    
    .item-con{
        width: 100%;
    }
    /* h2 {
        font-size: 1rem;
        margin-bottom: 10px;
      }
      p {
        font-size: 1rem;
        text-align: center;
      } */
    
  }
  @media screen and (max-width: 768px) {
    .main-container {
        height: 2100px;
        overflow: hidden;
    }
    .item {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .item-con{
        /* margin-left: 90px; */
        width: 100%;
    }
    .title {
        
        font-size: 35px;
    }
      
      h2 {
        font-size: 15px;
        margin-bottom: 10px;
      }
      p {
        font-size: 15px;
    }
  }

    @media screen and (max-width:480px){
        .main-container{
            height: 2600px;
        }
        .item {
            grid-template-columns: 1fr 1fr;
            margin-top: 70px;
            width: 90%;
        }
        .item-con{
            /* margin-left: 50px; */
            width: 100%;
        }
        h2 {
            font-size: 13px;
          }
          p {
            font-size: 12px;
        }
    }
    @media screen and (max-width: 320px){
        .main-container{
            height:4600px;
            overflow: hidden;
            
        }
        .item {
            grid-template-columns: 1fr;
            margin-top: 70px;
            width: 70%;
        }
        .item-con{
            /* margin-left: 40px; */
            width: 100%;
        }
        .buttons {
            margin-bottom: 5px;
        }
        h2 {
            font-size: 12px;
        }
        p {
            font-size: 11px;
        }
    }
.slide h1 {
	transition: all 0.3s ease;
	-webkit-transform: translateY(-20px);
					transform: translateY(-20px);
	opacity: 0;
}

.slide button {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide p {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide section * {
	transition: all 0.3s ease;
}

.slide section img {
	-webkit-transform: translateX(-10px);
					transform: translateX(-10px);
	opacity: 0;
}

.slide section span {
	-webkit-transform: translateY(-10px);
					transform: translateY(-10px);
	opacity: 0;
}

.slide section span strong {
	-webkit-transform: translateY(10px);
					transform: translateY(10px);
	opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	transition-delay: .9s;
					opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
	transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
	transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
	transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
	transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
	transition-delay: 1.5s;
}

.slide.animateOut h1 {
	transition-delay: .3s;
}

.slide.animateOut p {
	transition-delay: .2s;
}

.slide.animateOut section span {
	transition-delay: .1s;
}

.slide.animateOut section span strong {
	transition-delay: 0s;
}
button {
  -webkit-appearance: none;
          appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
          filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all .5s ease;
         filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  transition: all .5s ease;
  border: none;
  background: #b4b4b4;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
}

.button .a {
color: red;
}
button:hover {
  color: #FFFFFF;
  background: #222222;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}
button:active, button:focus {
  outline: none;
  border: none;
}

.wrapper {
  max-width: 1335px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.wrapper a:first-child {
  margin-left: 0;
}
.slider-container{
  height: 90vh;
  background-color: red;;
}

.slider-wrapper {
  position: relative;
  height: 90vh;
  overflow: hidden;
}

.slide {
  height: 90vh;
  background-size: cover !important;
}

.slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
    bottom: 0;
    left: 0;
}

.previousButton {
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		        transform: translateY(-50%);
		z-index: 10;
		background: url(/static/media/left-arrow.76877099.svg) no-repeat center center / 35px;
		width: 32px;
		height: 32px;
		text-indent: -9999px;
		cursor: pointer;
}

.nextButton{
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 10;
  background: url(/static/media/right-arrow.56b19681.svg) no-repeat center center / 35px;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  cursor: pointer;
}
.previousButton:hover{
  background: url(/static/media/left-arrow.76877099.svg) no-repeat center center / 35px;
}
.nextButton:hover {
  background: url(/static/media/right-arrow.56b19681.svg) no-repeat center center / 35px;
}

.previousButton {
  left: 15px;
  /* -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
          transform: rotate(180deg) translateY(calc(50% + 0px)); */
}

.previousButton:hover {
  left: 5px;
}

.nextButton {
  right: 15px;
}

.nextButton:hover {
  right: 5px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 60%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.slider-content .inner button{
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #FFFFFF;
  font-size: 30px;
  line-height: 1;
}

.slider-content .inner p {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px auto 30px;
  max-width: 640px;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #FFFFFF;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #FFFFFF;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {
  .slider-wrapper, .slide {
    height: calc(100vh - 75px);
  }   
  .slider-content .inner h1 {
    font-size: 32px;
  } 
}

@media (max-width: 640px) {
  .slider-wrapper, .slide {
    height: calc(110vh - 80px);
  }    
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }    
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 28px;
  }
}
.Toastify__toast--success {
    background: #B0A8B9;
  }
